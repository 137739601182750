import CanadianGamingImage from "@/../public/assets/images/upcoming/canadian-gaming-summit.webp";
import WlsImage from "@/../public/assets/images/upcoming/wla (1).webp";
import brand1 from "@/../public/assets/images/brand/brand-1.png";
import brand3 from "@/../public/assets/images/brand/brand-3.png";
import brand4 from "@/../public/assets/images/brand/brand-4.png";
import brand5 from "@/../public/assets/images/brand/brand-5.png";
import brand6 from "@/../public/assets/images/brand/brand-6.png";
import brand7 from "@/../public/assets/images/brand/AGE.webp";
import brand8 from "@/../public/assets/images/brand/Big.webp";
import brand9 from "@/../public/assets/images/brand/Sigma-africa.webp";
import brand10 from "@/../public/assets/images/brand/ALA.webp";
const home_upcoming = {
  bookMeetingButtonText: "STR_BOOK_MEETING",
  book_meeting: [
    {
      image: brand8,
      image_alt:
        "Poster for european lotteries event in 2025 with the event details",
      fromdate: "2025-01-31",
      todate: "2025-02-20",
      event: "bookmeets-canadian",
      heading: "BiG Africa Summit",
      event_date: "17-20 FEB'25",
      event_city: "Africa",
    },
    {
      image: brand7,
      image_alt:
        "Poster for european lotteries event in 2025 with the event details",
      fromdate: "2025-01-31",
      todate: "2025-02-28",
      event: "bookmeets-wls",
      heading: "AGE",
      event_date: "25-27 FEB '25",
      event_city: "Lagos",
    },
    {
      image: brand9,
      image_alt:
        "Poster for european lotteries event in 2025 with the event details",
      fromdate: "2025-01-31",
      todate: "2025-03-13",
      event: "bookmeets-canadian",
      heading: "Sigma Africa",
      event_date: "10-12 MARCH '25",
      event_city: "Africa",
    },
    {
      image: CanadianGamingImage,
      image_alt:
        "Poster for european lotteries event in 2023 with the event details",
      fromdate: "2024-05-24",
      todate: "2024-06-20",
      event: "bookmeets-canadian",
      heading: "Canadian Gaming Summit",
      event_date: "18-20 JUNE '24",
      event_city: "Toronto",
    },
    {
      image: WlsImage,
      image_alt:
        "Poster for european lotteries event in 2023 with the event details",
      fromdate: "2024-05-24",
      todate: "2024-10-24",
      event: "bookmeets-wls",
      heading: "WLS",
      event_date: "21-24 Oct '24",
      event_city: "Paris",
    },
  ],
  brand_certification: [
    {
      image: brand1,
      image_alt: "logo of World lottery association",
      href_link: "",
      image2: brand3,
      image_alt2:
        "logo of CORPORACIÃ“N IBEROAMERICANA DE LOTERï¿½?AS Y APUESTAS DEL ESTADO which is also called CIBELAE",
      href_link2: "",
      image3: brand4,
      image_alt3:
        "certification logo of betradar that mentions it is a certified platform from betradar",
      href_link3: "",
      image4: brand5,
      image_alt4:
        "certification logo of gaming labs that shows the RNG is certified by gaming labs",
      href_link4: "https://access.gaminglabs.com/Certificate/Index?i=410",
    },
    // ,
    // {
    //   image: brand3,
    //   image_alt:
    //     "logo of CORPORACIÃ“N IBEROAMERICANA DE LOTERï¿½?AS Y APUESTAS DEL ESTADO which is also called CIBELAE",
    //   href_link: "",
    // },
    // {
    //   image: brand4,
    //   image_alt:
    //     "certification logo of betradar that mentions it is a certified platform from betradar",
    //   href_link: "",
    // },
    // {
    //   image: brand5,
    //   image_alt:
    //     "certification logo of gaming labs that shows the RNG is certified by gaming labs",
    //   href_link: "https://access.gaminglabs.com/Certificate/Index?i=410",
    // },
    {
      image: brand6,
      image_alt: "logo of asia pacific lottery association(APLA)",
      href_link: "",
      image2: brand10,
      image_alt2: "logo of ALA",
      href_link2: "",
      image3: brand3,
      image_alt3:
        "logo of CORPORACIÃ“N IBEROAMERICANA DE LOTERï¿½?AS Y APUESTAS DEL ESTADO which is also called CIBELAE",
      href_link3: "",
      image4: brand4,
      image_alt4:
        "certification logo of betradar that mentions it is a certified platform from betradar",
      href_link4: "",
    },
    // ,
    // {
    //   image: brand1,
    //   image_alt: "logo of World lottery association",
    //   href_link: "",
    // },
    // {
    //   image: brand3,
    //   image_alt:
    //     "logo of CORPORACIÃ“N IBEROAMERICANA DE LOTERï¿½?AS Y APUESTAS DEL ESTADO which is also called CIBELAE",
    //   href_link: "",
    // },
    // {
    //   image: brand4,
    //   image_alt:
    //     "certification logo of betradar that mentions it is a certified platform from betradar",
    //   href_link: "",
    // },
  ],
};
export { home_upcoming };
