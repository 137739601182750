"use client";
import Link from "next/link";
import Image from "next/image";
import React, { useState } from "react";
/**Swiper */
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import { home_banner as homebanner } from "@/data/home_banner";
import useLanguageTranslations from "@/hooks/useLanguageTranslations";
import Loader from "../Loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight  } from "@fortawesome/free-solid-svg-icons";
export default function SinglePlatform({params}) {
  const translated = useLanguageTranslations(params.locale, "home");
  const [swiperRef, setSwiperRef] = useState(null);
  const prevHandler = () => {
    swiperRef.slidePrev();
  };
  const nextHandler = () => {
    swiperRef.slideNext();
  };
  if(!translated){
    return <Loader/>
  }
  return (
      <div className="section service-section-2 section-padding-03 single-background-img">
        <div className="container">
          <div className="service-wrap-2">
            <div className="section-title2 text-center single-max-width">
              <h1 className="title">
                <span>{translated(homebanner.singlePlateform)}</span>
                {translated(homebanner.singleLotteryWorld)}
              </h1>
              <p>{translated(homebanner.singlePLatformdesc)}</p>
            </div>
            <div className="service-content-wrap-2">
              <button
                onClick={prevHandler}
                className="carousel-control-prev solu prevSliderClassButton"
              >
                <span
                  className="carousel-control-prev-icon solu prevSliderClassSpan"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                onClick={nextHandler}
                className="carousel-control-next solu nextSliderClassButton"
              >
                <span
                  className="carousel-control-next-icon solu nextSliderClassSpan"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Next</span>
              </button>

              <Swiper
                spaceBetween={10}
                slidesPerView={1}
                onSwiper={(swiper) => setSwiperRef(swiper)}
                loop={true}
                mousewheel={false}
                pagination={{
                  clickable: true,
                }}
                modules={[Autoplay, Navigation]}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
              >
                {homebanner.crouselArray.map((crousel, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <div className="row">
                        <div className={homebanner.crouselClass1}>
                          <div className={homebanner.crouselClass2}>
                            <div className={homebanner.imageDivClass}>
                              <Link
                                className={homebanner.linkClass}
                                href={`/${params.locale}/${crousel.link}`}
                                aria-label={crousel.link}
                              >
                                <Image
                                  className="img-fluid"
                                  alt={crousel.alt}
                                  src={crousel.image}
                                  loading="lazy"
                                ></Image>
                              </Link>
                            </div>
                            <div className={homebanner.contentDivClass}>
                              <h3 className={homebanner.contentHeadingClass}>
                                <Link href={`/${params.locale}/${crousel.link}`} aria-label={crousel.link}>
                                  {translated(crousel.titleContent)}
                                </Link>
                              </h3>
                              <Link
                                className={homebanner.contentReadMore}
                                href={`/${params.locale}/${crousel.link}`}
                                aria-label={crousel.link}
                              >
                                {/* <i className={homebanner.faRightIconClass} /> */}
                                <FontAwesomeIcon icon={faLongArrowAltRight} />

                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className={homebanner.crouselClass1}>
                          <div className={homebanner.crouselClass2}>
                            <div className={homebanner.imageDivClass}>
                              <Link
                                className={homebanner.linkClass}
                                href={`/${params.locale}/${crousel.link2}`}
                                aria-label={crousel.link2}
                              >
                                <Image
                                  className="img-fluid"
                                  alt={crousel.alt2}
                                  src={crousel.image2}
                                  loading="lazy"
                                ></Image>
                              </Link>
                            </div>
                            <div className={homebanner.contentDivClass}>
                              <h3 className={homebanner.contentHeadingClass}>
                                <Link href={`/${params.locale}/${crousel.link2}`} aria-label={crousel.link2}>
                                  {translated(crousel.titleContent2)}
                                </Link>
                              </h3>
                              <Link
                                className={homebanner.contentReadMore}
                                href={`/${params.locale}/${crousel.link2}`}
                                aria-label={crousel.link2}
                              >
                                {/* <i className={homebanner.faRightIconClass} /> */}

                                <FontAwesomeIcon icon={faLongArrowAltRight} />
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className={homebanner.crouselClass1}>
                          <div className={homebanner.crouselClass2}>
                            <div className={homebanner.imageDivClass}>
                              <Link
                                className={homebanner.linkClass}
                                href={`/${params.locale}/${crousel.link3}`}
                                aria-label={crousel.link3}
                              >
                                <Image
                                  className="img-fluid"
                                  alt={crousel.alt3}
                                  src={crousel.image3}
                                  loading="lazy"
                                ></Image>
                              </Link>
                            </div>
                            <div className={homebanner.contentDivClass}>
                              <h3 className={homebanner.contentHeadingClass}>
                                <Link href={`/${params.locale}/${crousel.link3}`} aria-label={crousel.link3}>
                                  {translated(crousel.titleContent3)}
                                </Link>
                              </h3>
                              <Link
                                className={homebanner.contentReadMore}
                                href={`/${params.locale}/${crousel.link3}`}
                                aria-label={crousel.link3}
                              >
                                {/* <i className={homebanner.faRightIconClass} /> */}
                                <FontAwesomeIcon icon={faLongArrowAltRight} />

                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
  );
}
