import image1 from "@/../public/assets/images/homebanner/banner-01-new-graphic.webp";
import image2 from "@/../public/assets/images/homebanner/banner-03-new-graphic.webp";
import image3 from "@/../public/assets/images/homebanner/banner-02-new-graphic.webp";
import image4 from "@/../public/assets/images/homebanner/wls.webp";
const banner = {

    crouselClass1: "row",
    imageDivClass: "images",
    contentHeadingClass: "title",
    contentHeadingClass1: "sub-title",
    crouselArray: [
        
        {
            image: image2,
            titleContent: "STR_INSTANT_LOTTERY",
            titleContent1: "STR_BEST_IN_CLASS_PHYSICAL",
            titleContent2: "STR_DIGITAL_SCREACH",
            alt: "Group of POS lottery machines and mobile devices with a few lottery tickets flying and a man riding a racing horse",
            crouselClass: "hero-images sk",
            contentDivClass: "hero-content",
            bannerKey: true,
        },
        
        {
            image: image3,
            titleContent: "STR_INFINITY_GAMING_PLATFORM",
            titleContent1: "STR_SUPPORT_ALL_CHANNELS",
            titleContent2: "STR_ALL_GAMES",
            alt: "Group of logos of lottery associations that skilrock is a member",
            crouselClass: "hero-images sk",
            contentDivClass: "hero-content",
            bannerKey: true,
        },
        {
            image: image1,
            titleContent: "STR_MOST_EXP",
            titleContent1: "STR_RECOGNISED_VALUABLE",
            titleContent2: "STR_RECOGNISED_VALUABLE_1",
            alt: "Group of POS lottery machines and mobile devices with a few lottery tickets flying and a man riding a racing horse",
            crouselClass: "hero-images sk",
            contentDivClass: "hero-content",
            bannerKey: true,
        },
    ],
};
export { banner };
