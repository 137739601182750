"use client";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { banner as banner } from "@/data/banner";
import Image from "next/image";
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import React,{ useEffect } from "react";
import useLanguageTranslations from "@/hooks/useLanguageTranslations";
import Loader from "../Loader/Loader";
import Link from "next/link";
import BannerBackground from "@/../public/assets/images/homebanner/master-bg.webp";
function Banner({params}) {
  const translated = useLanguageTranslations(params.locale, "home");
  useEffect(()=>{
    AOS.init();
  },[])
  if(!translated){
    return <Loader/>
  }
  return (
    <>
      <div className="home-6-hero-swiper section tech-hero-section-6">
        <div className="home-banner-backgound-image-set">
            <Image 
            className="home-banner-backgound-image-set-image"
            src={BannerBackground} 
            alt="Background"
            priority
          />
          <Swiper
          className="home-banner-backgound-image-set-swiper container-fluid"
            spaceBetween={10}
            slidesPerView={1}
            loop={true}
            mousewheel={false}
            navigation={true}
            autoplay={{
              delay: 3600,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Autoplay, Navigation]}
          >
            {banner.crouselArray.map((crousel, index) => {
              if (crousel.bannerKey) {
                return (
                  <SwiperSlide key={index}>
                  <div className={`home_banner_div ${banner.crouselClass1}`}>
                    {/* <div className="col-md-1 col-0"></div> */}
                    <div className="col-lg-6 col-12 col-md-5 d-flex align-items-center justify-content-center long-bg">
                    {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-sx-12 d-flex align-items-center justify-content-center long-bg"> */}
                      <div className={`${crousel.crouselClass}, d-flex justify-content-center`}>
                        <div className="home_banner_div_img">
                          <Image
                            alt={crousel.alt}
                            src={crousel.image}
                            priority={index === 0}
                            className="img-fluid"
                            // style={{width:'98%'}}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-1 col-0"></div> */}
                    <div className="col-md-6 col-12 d-flex align-items-center justify-content-md-center justify-content-center">
                    {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-sx-12 d-flex align-items-center justify-content-center long-bg"> */}
                      <div className={`image-banner-mobile`}>
                        {crousel.titleContent && (
                          <h2
                            className={`${banner.contentHeadingClass}`}
                            data-aos="fade-up"
                            data-aos-delay="800"
                          >
                            { crousel.titleContent1 && <span className="d-block" style={{ fontWeight: "bold" }}>
                              {translated(crousel.titleContent)}
                            </span>}
                            { crousel.titleContent1 && <span className="d-block">
                              {translated(crousel.titleContent1)}
                            </span>}
                            { crousel.titleContent2 && <span className="d-block">
                              {translated(crousel.titleContent2)}
                            </span>}
                          </h2>
                        )}
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                )
              } else {
                return (
                  <SwiperSlide key={index}>
                    <div className={banner.crouselClass1} >
                      <div className="col-md-2 col-0"></div>
                      <div className="col-lg-8 col-12 col-md-8 d-flex align-items-center justify-content-center long-bg">
                      {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-sx-12 d-flex align-items-center justify-content-center long-bg"> */}
                        <div className={crousel.crouselClass}>
                          <div className={banner.imageDivClass}>

                            <Image
                              alt={crousel.alt}
                              src={crousel.image}
                              priority
                              className="img-fluid web"
                            />
                            <center><Link href="https://calendly.com/skilrock/30min"
                              target="_blank"><button type="submit" className="btn banner"><span
                                className="btn-content">Book a
                                Meeting</span></button></Link>
                            </center>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-1 col-0"></div>
                    </div>
                  </SwiperSlide>
                )
              }
            })}
            
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default React.memo(Banner);
